/**
 * Don't touch :)
 */

import * as React from 'react';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';

const PreviewPage = () => {
  return (
    <div
      style={{
        height: '500px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h2>Loading preview…</h2>
    </div>
  );
};

export default withPrismicPreviewResolver(PreviewPage);
